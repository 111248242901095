import Iconify from "../iconify";
import SvgColor from "../svg-color";

export const icon = (name: string) => (
    <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
    // OR
    // <Iconify icon="fluent:mail-24-filled" />
    // https://icon-sets.iconify.design/solar/
    // https://www.streamlinehq.com/icons
  );
  
  export const iconify = (name: string) => (
    <Iconify icon={name} width={32} />
  );
  