import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';

// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// Azure
const AzureLoginPage = lazy(() => import('src/pages/auth/azure/login'));
const AzureRegisterPage = lazy(() => import('src/pages/auth/azure/register'));

// ----------------------------------------------------------------------

const authAzure = {
  path: 'azure',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthClassicLayout>
          <AzureLoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthClassicLayout>
          <AzureRegisterPage />
        </AuthClassicLayout>
      ),
    },
  ],
};

export const authRoutes = [
  {
    path: 'auth',
    children: [authAzure],
  },
];
