
export const API_ENDPOINTS = {
    v1: {
        globalAdmins: 'api/v1/globaladmins',
        registrations: 'api/v1/registrations',
        tenants: 'api/v1/tenants',
        tenantStats: 'api/v1/tenantstats',
        analyticsModules: 'api/v1/analytics-modules',
        modules: 'api/v1/modules',
    }
}

