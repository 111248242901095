import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './msal-config';

let currentUserEmail = '';

export const setCurrentUserEmail = (email: string) => {
    currentUserEmail = email;
};
export const getCurrentUserEmail = () => currentUserEmail;

export const msalGlobalInstance = new PublicClientApplication(msalConfig);


msalGlobalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const { account } = payload;
        msalGlobalInstance.setActiveAccount(account);
    }
});
