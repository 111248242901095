// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';


// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// theme


// ----------------------------------------------------------------------


type Props = {
  title?: string;
  image?: string;
  children: React.ReactNode;
};

export default function AuthClassicLayout({ children, image, title }: Props) {

  const theme = useTheme();

  const upMd = useResponsive('up', 'md');

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 480,
        px: { xs: 2, md: 8 },
        py: { xs: 15, md: 30 },
        backgroundColor: theme.palette.background.neutral,
      }}
    >
      {children}
    </Stack>
  );

  const renderSection = (
    <Stack
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
      spacing={10}
      sx={{
        backgroundColor: theme.palette.background.docread
      }}
    >
      <Box
        component="img"
        alt="auth"
        src={image || '/assets/illustrations/logo-08-white.png'}
        // src={image || theme.palette.mode === 'light' ? '/assets/illustrations/logo-08.png' : '/assets/illustrations/logo-08-white.png'}
        sx={{ maxWidth: 720 }}
      />

    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
      }}
    >

      {upMd && renderSection}

      {renderContent}
    </Stack>
  );
}
