import axios from 'axios';
import { msalGlobalInstance } from 'src/auth/context/azure/msal-global-instance';
// config
import { HOST_API, AZURE_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

axiosInstance.interceptors.request.use(
  async (config) => {
    const loginRequest = {
      account: msalGlobalInstance.getActiveAccount() ?? undefined,
      scopes: AZURE_API.docReadAcessTokens
      // scopes: AZURE_API.accessTokens
    };

    try {
      const response = await msalGlobalInstance.acquireTokenSilent(loginRequest);
      const bearer = `Bearer ${response.accessToken}`;
      config.headers.Authorization = bearer;
      config.headers['Content-Type'] = 'application/json';
    }
    catch (error) {
      console.log(error);
    }

    return config;

  },
  (error) => Promise.reject(error)
);


export default axiosInstance;
