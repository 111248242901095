import { API_ENDPOINTS } from "./api-endpoints";
import { ServiceBase } from "./service-base";

export class UserAdminService {

    public static async isUserGlobalAdmin(): Promise<boolean | null> {
        const isUserAdmin = await ServiceBase.get<boolean>(`${API_ENDPOINTS.v1.globalAdmins}/isAdmin`);
        return isUserAdmin;
    }

    /*
        public static async isUserGlobalAdmin(accessToken: string): Promise<boolean | null> {
        
        const bearer = `Bearer ${accessToken}`;
        const config = {
            headers: {
                Authorization: bearer,
                'Content-Type': 'application/json'
            }            
        };
            
        const isUserAdmin = await ServiceBase.get<boolean>(`${API_ENDPOINTS.v1.globalAdmins}/isAdmin`, config);
        return isUserAdmin;
    }

    */

}


