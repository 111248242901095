import axiosInstance from "src/utils/axios";
import { AxiosRequestConfig } from "axios";
// import { ServiceError } from "./service-error";


export class ServiceBase {

    public static async get<T>(url: string, config?: AxiosRequestConfig<any>): Promise<T | null> {

        const response = await axiosInstance.get<T>(url, config);

        if (response.status === 404) {
            return null;
        }

        /*
        if (response.status !== 200) {
            throw new ServiceError(response.status.toString(), response.statusText);
        }
        */

        return response.data;

    }

    public static async put<T>(url: string, data: any) {

        const response = await axiosInstance.put<T>(url, data);

        /*
        if (response.status !== 200) {
            throw new ServiceError(response.status.toString(), response.statusText);
        }
        */

        return response.data;
    }


    public static async post<T>(url: string, data: any) {

        const response = await axiosInstance.post<T>(url, data);

        /*
        if (response.status !== 200) {
            throw new ServiceError(response.status.toString(), response.statusText);
        }
        */

        return response.data;
    }

    public static async delete(url: string, config?: AxiosRequestConfig<any>) {

        const response = await axiosInstance.delete(url, config);

        /*
        if (response.status !== 200) {
            throw new ServiceError(response.status.toString(), response.statusText);
        }
        */

        return response.data;

    }
}